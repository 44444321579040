import {
  createStyles,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import firebase from 'firebase/app';
import React, { useCallback, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import {
  CollectionRoomUsers,
  DictionariumData,
  DictionariumPhase,
  DictionariumResultsData,
  DictionariumThinkingData,
  DictionariumVotingData,
} from '../../../collection/types';
import Define from './Define';
import { data as fakewords } from '../../../data/fake-words.json';
import randomElement from './utility';
import Rules from './Rules';
import Vote, { PlayerSubmission, SubmissionResult } from './Vote';
import Results from './Results';
import { ScoreUpdate } from '../../../collection/utility';

const randomWord = (): string => {
  return randomElement(fakewords).word;
};

type DictionariumProps = {
  gameData: DictionariumData;
  isOwner: boolean;
  user: firebase.User;
  users: CollectionRoomUsers;
  updateData: (gameData: DictionariumData) => Promise<void>;
  updatePartialData: (gameData: DictionariumData) => Promise<void>;
  onGameEnd: () => Promise<void>;
  updateScores: (updates: ScoreUpdate[]) => Promise<void>;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
  })
);

const Dictionarium: React.FC<DictionariumProps> = ({
  gameData,
  user,
  users,
  isOwner,
  updateData,
  updatePartialData,
  updateScores,
  onGameEnd,
}) => {
  const classes = useStyles();

  const startGame = async (): Promise<void> => {
    await updateData({
      currentPhase: DictionariumPhase.thinking,
      currentWord: randomWord(),
      choices: {},
    });
  };

  const getSubmissions = (): PlayerSubmission[] => {
    const { choices } = gameData as DictionariumVotingData;
    return (
      choices! &&
      Object.entries(choices).map(([uid, definition]) => ({
        playerName: users[uid].name,
        playerUID: uid,
        definition,
      }))
    );
  };

  const getVotes = useCallback((): SubmissionResult[] => {
    const { votes, choices } = gameData as DictionariumResultsData;

    // only God can judge me
    const participants = votes! && Array.from(new Set(Object.values(votes)));

    return (
      votes! &&
      participants.map((uid) => ({
        playerName: users[uid].name,
        playerUID: uid,
        definition: choices![uid],
        voteCount: Math.ceil(
          (10 *
            Object.values(votes).filter((currentUID) => currentUID === uid)
              .length) /
            (Object.values(votes).length - 1)
        ),
      }))
    );
  }, [gameData, users]);

  const setDefinition = async (definition: string): Promise<void> => {
    const currentData: DictionariumThinkingData = { choices: {} };
    currentData.choices![user.uid] = definition;
    await updatePartialData(currentData);
  };

  const setVote = async (target: string): Promise<void> => {
    const currentData: DictionariumVotingData = { votes: {} };
    currentData.votes![user.uid] = target;
    await updatePartialData(currentData);
  };

  const getPhaseComponent = () => {
    switch (gameData.currentPhase) {
      case DictionariumPhase.rules:
        return <Rules isOwner={isOwner} onHostValidate={startGame} />;
      case DictionariumPhase.thinking:
        return (
          <Define
            word={gameData.currentWord!}
            sendDefinition={setDefinition}
            choices={gameData.choices!}
            ownChoice={gameData.choices![user.uid]}
          />
        );
      case DictionariumPhase.voting:
        return (
          <Vote
            definedWord={gameData.currentWord!}
            otherSubmissions={getSubmissions().filter(
              (s) => s.playerUID !== user.uid
            )}
            sendVote={setVote}
          />
        );
      case DictionariumPhase.results:
        return (
          <Results
            definedWord={gameData.currentWord!}
            results={getVotes()}
            isOwner={isOwner}
            endGame={onGameEnd}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!isOwner) return;
    if (
      gameData.currentPhase === DictionariumPhase.thinking &&
      gameData.choices! &&
      Object.values(gameData.choices).length >= Object.values(users).length
    ) {
      updatePartialData({
        currentPhase: DictionariumPhase.voting,
      });
    } else if (
      gameData.currentPhase === DictionariumPhase.voting &&
      gameData.votes! &&
      Object.values(gameData.votes).length >= Object.values(users).length
    ) {
      updatePartialData({
        currentPhase: DictionariumPhase.results,
      });
      updateScores(
        getVotes().map((result) => ({
          uid: result.playerUID,
          increment: result.voteCount,
        }))
      );
    }
  }, [
    gameData,
    getVotes,
    isOwner,
    updateData,
    updatePartialData,
    updateScores,
    users,
  ]);

  const stepper = isDesktop && (
    <Stepper activeStep={gameData.currentPhase}>
      <Step key={DictionariumPhase.rules}>
        <StepLabel>Rules</StepLabel>
      </Step>
      <Step key={DictionariumPhase.thinking}>
        <StepLabel>Define!</StepLabel>
      </Step>
      <Step key={DictionariumPhase.voting}>
        <StepLabel>Vote!</StepLabel>
      </Step>
      <Step key={DictionariumPhase.results}>
        <StepLabel>Results</StepLabel>
      </Step>
    </Stepper>
  );

  return (
    <Paper className={classes.paper}>
      <Typography variant="h2" align="center">
        Dictionarium
      </Typography>
      {stepper}

      {getPhaseComponent()}
    </Paper>
  );
};

export default Dictionarium;

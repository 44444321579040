import { Invention } from '../components/games/PatentlyStupid/Invent';
import { Investment } from '../components/games/PatentlyStupid/Invest';
import { Score } from '../components/games/PatentlyStupid/Results';

export type CollectionRoomUser = {
  name: string;
  score: number;
};

export type CollectionRoomUsers = Record<string, CollectionRoomUser>;

export enum DictionariumPhase {
  rules,
  thinking,
  voting,
  results,
}

export type DictionariumRulesData = {
  currentPhase: DictionariumPhase.rules;
};

export type DictionariumThinkingData = {
  currentPhase?: DictionariumPhase.thinking;
  currentWord?: string;
  choices?: Record<string, string>;
};

export type DictionariumVotingData = {
  currentPhase?: DictionariumPhase.voting;
  currentWord?: string;
  choices?: Record<string, string>;
  votes?: Record<string, string>;
};

export type DictionariumResultsData = {
  currentPhase: DictionariumPhase.results;
  currentWord?: string;
  choices?: Record<string, string>;
  votes?: Record<string, string>;
};

export type DictionariumData =
  | DictionariumRulesData
  | DictionariumThinkingData
  | DictionariumVotingData
  | DictionariumResultsData;

export type DictionariumGame = {
  game: 'dictionarium';
  data: DictionariumData;
};

export enum WordSpudPhase {
  rules,
  prompting,
  voting,
  results,
}

export type WordSpudRulesData = {
  currentPhase: WordSpudPhase.rules;
};

export type WordSpudPromptingData = {
  currentPhase: WordSpudPhase.prompting;
  currentExpression: string;
  currentTurnPlayer: string;
  playerChoice?: string;
  scores: Record<string, number>;
};

export type WordSpudVotingData = {
  currentPhase?: WordSpudPhase.voting;
  currentExpression?: string;
  currentTurnPlayer?: string;
  playerChoice?: string;
  votes?: Record<string, boolean>;
  scores?: Record<string, number>;
};

export type WordSpudResultsData = {
  currentPhase: WordSpudPhase.results;
  scores: Record<string, number>;
};

export type WordSpudData =
  | WordSpudRulesData
  | WordSpudPromptingData
  | WordSpudVotingData
  | WordSpudResultsData;

export type WordSpudGame = {
  game: 'wordspud';
  data: WordSpudData;
};

export enum PatentlyStupidPhase {
  rules,
  problem,
  invent,
  present,
  invest,
  results,
}

export type PatentlyStupidRulesData = {
  currentPhase: PatentlyStupidPhase.rules;
};

export type PatentlyStupidProblemData = {
  currentPhase?: PatentlyStupidPhase.problem;
  problems?: Record<string, string>;
};

export type PatentlyStupidInventData = {
  currentPhase?: PatentlyStupidPhase.invent;
  problems?: Record<string, string>;
  solvers?: Record<string, string>; // user uid -> problem uid
  inventions?: Record<string, Invention>; // user uid -> {problem uid, invention name, tagline}
};

export type PatentlyStupidPresentData = {
  currentPhase?: PatentlyStupidPhase.present;
  turnsPlayer: string[];
  problems?: Record<string, string>;
  inventions?: Record<string, Invention>; // user uid -> {problem uid, invention name, tagline}
};

export type PatentlyStupidInvestData = {
  currentPhase?: PatentlyStupidPhase.invest;
  problems?: Record<string, string>;
  inventions?: Record<string, Invention>; // user uid -> {problem uid, invention name, tagline}
  investments?: Record<string, Investment>;
};

export type PatentlyStupidResultsData = {
  currentPhase: PatentlyStupidPhase.results;
  problems?: Record<string, string>;
  inventions?: Record<string, Invention>; // user uid -> {problem uid, invention name, tagline}
  scores: Record<string, Score>;
};

export type PatentlyStupidData =
  | PatentlyStupidRulesData
  | PatentlyStupidProblemData
  | PatentlyStupidInventData
  | PatentlyStupidPresentData
  | PatentlyStupidInvestData
  | PatentlyStupidResultsData;

export type PatentlyStupidGame = {
  game: 'patentlystupid';
  data: PatentlyStupidData;
};

export type GameData = DictionariumGame | WordSpudGame | PatentlyStupidGame;

export const games = ['dictionarium', 'wordspud', 'patentlystupid'] as const;

export type CollectionRoom = {
  owner: string;
  status: 'waiting' | 'ingame';
  users: CollectionRoomUsers;
  gameData?: GameData;
};

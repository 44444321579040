import { Grid, Typography, TextField, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useState } from 'react';

export type DefineProps = {
  word: string;
  sendDefinition: (definition: string) => Promise<void>;
  choices: Record<string, string>;
  ownChoice?: string;
};

const Define: React.FC<DefineProps> = ({
  word,
  sendDefinition,
  choices,
  ownChoice,
}) => {
  const [validated, setValidated] = useState(false);
  const [definition, setDefinition] = useState('');

  const onValidateWord = async () => {
    setValidated(true);
    await sendDefinition(definition);
  };

  const getValidations = () => {
    const definitionEntries =
      choices! &&
      Object.entries(choices).map(([uid]) => (
        <Grid item key={uid}>
          <CheckCircleIcon fontSize="large" htmlColor="green" />
        </Grid>
      ));
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Your definition for &quot;{word}&quot;: <b>{ownChoice}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Be patient while the others are defining...
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          spacing={4}
          xs={12}
        >
          {definitionEntries}
        </Grid>
      </>
    );
  };

  const getContent = () => (
    <>
      <Grid item xs={12}>
        <Typography variant="h4">Define the word: {word!}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          label="Definition"
          fullWidth
          multiline
          onInput={async (e) => {
            const el = e.target as HTMLInputElement;
            const { value } = el;
            setDefinition(value);
            setValidated(false);
          }}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={onValidateWord}
          disabled={definition.length === 0}
        >
          {validated ? 'Validated!' : 'Validate'}
        </Button>
      </Grid>
    </>
  );

  return (
    <Grid container spacing={3}>
      {ownChoice ? getValidations() : getContent()}
    </Grid>
  );
};

export default Define;

import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useHistory } from 'react-router-dom';
import { getFirestore } from '../../config/firebase';
import { CollectionRoom } from '../../collection/types';
import { removeUserFromRoom } from '../../collection/utility';
import UserContext from '../../context/user-context';
import RoomUsers from './RoomUsers';

const useStyles = makeStyles(() =>
  createStyles({
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cancelButton: {
      background: 'linear-gradient(45deg, #4568dc 30%, #b06ab3 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
      margin: 8,
    },
    roomCode: {
      fontSize: '4em',
      fontWeight: 'bolder',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

interface HomeProps {
  values: {
    username: string;
    hostCode: string;
    roomCode: string;
    usernameTaken: boolean;
    invalidCode: boolean;
    hosting: boolean;
    joining: boolean;
  };
  setValues: React.Dispatch<
    React.SetStateAction<{
      username: string;
      hostCode: string;
      roomCode: string;
      usernameTaken: boolean;
      invalidCode: boolean;
      hosting: boolean;
      joining: boolean;
    }>
  >;
}

const db = getFirestore();
const roomsCollection = db.collection('rooms');

const JoinDialog: React.FC<HomeProps> = ({ values, setValues }) => {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext)!;

  const roomsQuery = roomsCollection.doc(values.roomCode || '-1');
  const [room, loading] = useDocumentData<CollectionRoom>(roomsQuery);

  if (values.joining && !loading && room == null)
    setValues({
      ...values,
      joining: false,
    });

  if (room != null && room.status === 'ingame') {
    history.push(`/room/${values.roomCode}`);
    return null;
  }

  const handleClose = async () => {
    setValues({
      ...values,
      joining: false,
    });
    await removeUserFromRoom(values.roomCode, user.uid);
  };

  const usersInfo = Object.entries(room?.users || {})
    .sort(([, a], [, b]) => a.name.localeCompare(b.name))
    .map(([uid, curUser]) => ({
      name: curUser.name,
      uid,
      owner: uid === room?.owner,
    }));

  return (
    <div>
      <Dialog
        open={values.joining}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5">Waiting for the host to start</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.center}>
              <CircularProgress color="primary" />
            </div>
          </DialogContentText>
          <DialogContent>
            <RoomUsers users={usersInfo} />
          </DialogContent>
        </DialogContent>
        <DialogActions className={classes.center}>
          <Button className={classes.cancelButton} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default JoinDialog;

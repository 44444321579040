import { createStyles, CssBaseline, makeStyles } from '@material-ui/core';
import React from 'react';
import { Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import Home from './routes/Home';
import Room from './routes/Room';
import UserContext from '../context/user-context';
import { getAuth } from '../config/firebase';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      paper: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
          width: 600,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
    },
    fullSize: {
      height: '100%',
    },
  })
);

const auth = getAuth();

const App: React.FC = () => {
  const classes = useStyles();

  const [user] = useAuthState(auth);

  return (
    <div>
      <header>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </header>

      <UserContext.Provider value={user}>
        <div className={classes.root}>
          <CssBaseline />

          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/room">
              <Room />
            </Route>
          </main>
        </div>
      </UserContext.Provider>
    </div>
  );
};

export default App;

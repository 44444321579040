import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';

type WordSpudRulesProps = {
  isOwner: boolean;
  onHostValidate: () => Promise<void>;
};

const Rules: React.FC<WordSpudRulesProps> = ({ isOwner, onHostValidate }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          One player is given a simple word, for example &quot;real&quot;. The
          player must complete that word with a fitting or witty word or
          expression, like &quot;world&quot;.
        </Typography>
        <Typography variant="body1">
          Each player then votes on the entry. If the final score is positive,
          it becomes the prompt for the next player.
        </Typography>
      </Grid>
      {isOwner && (
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onHostValidate}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Rules;

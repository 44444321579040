import React, { useContext, useState } from 'react';
import {
  createStyles,
  makeStyles,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import UserContext from '../../context/user-context';
import { getAuth } from '../../config/firebase';
import JoinDialog from './JoinDialog';
import HostDialog from './HostDialog';
import {
  roomCodeExists,
  addUserToRoom,
  registerRoom,
  isUsernameTaken,
  isValidCode,
} from '../../collection/utility';

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: { transform: 'translateY(-3vh)' },
    roomField: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(1),
    },
    joinButton: {
      background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
      margin: 8,
    },
    hostButton: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
      margin: 8,
    },
  })
);

const auth = getAuth();

/* Utility functions */

const generateCode = async (): Promise<string> => {
  const code: string = String(Math.floor(9999 * Math.random())).padStart(
    4,
    '0'
  );
  return (await roomCodeExists(code)) ? generateCode() : code;
};

const Content: React.FC = () => {
  const classes = useStyles();
  const [values, setValues] = useState({
    username: '',
    hostCode: '',
    roomCode: '',
    usernameTaken: false,
    invalidCode: false,
    hosting: false,
    joining: false,
  });
  const user = useContext(UserContext);

  const joinRoom = async (): Promise<void> => {
    const newUser = user || (await auth.signInAnonymously()).user!;
    if (await roomCodeExists(values.roomCode)) {
      setValues({
        ...values,
        joining: true,
      });
      await addUserToRoom(values.roomCode, newUser.uid, values.username);
      // history.push(`/room/${values.roomCode}`);
    }
  };

  const createAndJoinRoom = async (): Promise<void> => {
    const newUser = user || (await auth.signInAnonymously()).user!;
    const code = await generateCode();
    setValues({
      ...values,
      hostCode: code,
      hosting: true,
    });
    await registerRoom(code, newUser.uid, values.username);
  };

  const handleRoomCodeKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' && isValidCode(values.roomCode)) joinRoom();
  };

  const logo = () => (
    <svg width={isMobile ? '85vw' : '30vw'} height="auto" viewBox="0 0 600 128">
      <g id="logo-group">
        <g id="logo-center" transform="translate(0 0)">
          <g>
            <g>
              <defs>
                <polygon id="SVGID_1_" points="-211,-319 -210,-319 -211,-318" />
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" overflow="visible" />
              </clipPath>
            </g>
          </g>
          <g id="slogan" transform="translate(0 0)" />
          <g id="title" transform="translate(0 0)">
            <path
              id="path90732"
              fill="#A9AFB5"
              d="M22.2,92.4h7.2V67h25.5v-6H22.2V92.4z M22.2,35.6v18.1h7.2V42h30.3v-6.4H22.2z"
            />
            <path
              id="path90734"
              fill="#ADCFDF"
              d="M85.5,92.4h7.2V35.6h-7.2V92.4z"
            />
            <path
              id="path90736"
              fill="#B0D1DC"
              d="M152.5,70.6c1.8-0.4,3.4-1.2,4.9-2.3c1.4-1,2.7-2.3,3.8-3.8c1-1.4,1.8-3.1,2.4-5
				c0.6-1.8,0.9-3.8,0.9-5.8c0-2.2-0.5-4.4-1.3-6.6c-0.9-2.2-2.1-4.1-3.6-5.8c-1.5-1.8-3.4-3.1-5.4-4.2c-2.1-1-4.4-1.6-6.9-1.6H123
				v56.7h7.2V72h7.2l-4-6.4h-3.2V42h16.7c1.4,0,2.6,0.3,3.9,1c1.3,0.6,2.3,1.4,3.3,2.6c1,1.1,1.7,2.3,2.2,3.8s0.9,3,0.9,4.5
				c0,1.6-0.2,3-0.7,4.5c-0.6,1.5-1.2,2.7-2,3.8c-0.9,1.1-1.9,2-3.1,2.6c-1.2,0.6-2.6,1-4,1H141l4.2,6.4l12.9,20.4h8.2L152.5,70.6z"
            />
            <path
              id="path90738"
              fill="#B2D3DA"
              d="M199.2,42h30.6v-6.4H192v17.6h7.2V42z M192,86v6.4h38.5V86H192z M199.2,66.4h26.6v-6H192
				v18.4h7.2V66.4z"
            />
            <path
              id="path90740"
              fill="#B4D6D8"
              d="M297.7,68.6c-1.9-2.6-4.5-4.4-7.7-5.4c2.6-1.1,4.6-3,6-5.4c1.4-2.3,2.2-5,2.2-7.8
				c0-1.8-0.3-3.4-1-5.2s-1.5-3.3-2.6-4.6c-1.1-1.4-2.5-2.5-4.1-3.4c-1.6-0.8-3.4-1.3-5.4-1.3H257v44h7.2V41.9h18.5
				c1.1,0,2.2,0.2,3.3,0.7c1,0.6,1.9,1.2,2.6,2c0.7,0.9,1.3,1.9,1.8,3c0.4,1.2,0.6,2.5,0.6,3.8c0,1.4-0.2,2.6-0.7,3.7
				c-0.5,1.1-1,2.2-1.8,3c-0.8,0.9-1.7,1.6-2.7,2.1c-1.1,0.5-2.3,0.7-3.5,0.7h-10.9v5.8h13.1c1.3,0,2.4,0.3,3.5,0.8
				c1,0.6,1.9,1.3,2.7,2.2c0.8,1,1.4,2,1.8,3.2s0.7,2.4,0.7,3.7s-0.2,2.6-0.7,3.7c-0.6,1.2-1.2,2.2-2,3.1c-0.9,0.9-1.9,1.6-3,2.1
				c-1.1,0.6-2.4,0.8-3.7,0.8H257v6.2h27c2.2,0,4.4-0.3,6.4-1c2-0.6,3.8-1.7,5.3-3s2.7-2.8,3.6-4.6c0.9-1.8,1.4-3.8,1.4-6
				C300.6,74.2,299.6,71.2,297.7,68.6L297.7,68.6z"
            />
            <path
              id="path90742"
              fill="#B6D8D5"
              d="M357,70.6c1.8-0.4,3.4-1.2,4.9-2.3c1.4-1,2.7-2.3,3.8-3.8c1-1.4,1.8-3.1,2.4-5
				c0.6-1.8,0.9-3.8,0.9-5.8c0-2.2-0.5-4.4-1.3-6.6c-0.9-2.2-2.1-4.1-3.6-5.8c-1.5-1.8-3.4-3.1-5.4-4.2c-2.1-1-4.4-1.6-6.9-1.6
				h-24.3v56.7h7.2V72h7.2l-4-6.4h-3.2V42h16.7c1.4,0,2.6,0.3,3.9,1c1.3,0.6,2.3,1.4,3.3,2.6c1,1.1,1.7,2.3,2.2,3.8
				c0.6,1.4,0.9,3,0.9,4.5c0,1.6-0.2,3-0.7,4.5c-0.6,1.5-1.2,2.7-2,3.8c-0.9,1.1-1.9,2-3.1,2.6s-2.6,1-4,1h-6.3l4.2,6.4l12.9,20.4
				h8.2L357,70.6z"
            />
            <path
              id="path90744"
              fill="#B9DAD3"
              d="M403.7,42h30.6v-6.4h-37.8v17.6h7.2L403.7,42L403.7,42z M396.5,86v6.4H435V86H396.5z
				 M403.7,66.4h26.6v-6h-33.8v18.4h7.2L403.7,66.4L403.7,66.4z"
            />
            <path
              id="path90746"
              fill="#BBDCD0"
              d="M488,43.4l-3.1,8.5l7.2,18.3h-14.2l-2.2,5.6h18l6.7,16.6h7.7L488,43.4z M484.9,35.6h-6.1
				l-23.3,56.7h7.4l18.9-48.4L484.9,35.6z"
            />
            <path
              id="path90748"
              fill="#BDDECE"
              d="M539.5,35.7h-7.2v29.7l7.2-7.8V35.7z M553.8,60.6l22.8-25h-7.8L539.5,67l-7.2,7.7v17.7h7.2
				V75l9.8-10.2L570,92.4h7.8L553.8,60.6z"
            />
          </g>
          <g>
            <g>
              <defs>
                <polygon id="SVGID_3_" points="-211,-319 -210,-319 -211,-318" />
              </defs>
              <clipPath id="SVGID_4_">
                <use href="#SVGID_3_" overflow="visible" />
              </clipPath>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      <Grid item className={classes.logo}>
        {logo()}
      </Grid>
      <Grid item xs={12} className={classes.roomField}>
        <TextField
          autoFocus
          label="Username"
          onInput={async (e) => {
            const el = e.target as HTMLInputElement;
            const { value } = el;
            el.value = value.slice(0, 12);
            setValues({
              ...values,
              username: el.value,
              usernameTaken: await isUsernameTaken(values.roomCode, el.value),
            });
          }}
          onKeyPress={handleRoomCodeKeyPress}
          helperText={values.usernameTaken ? 'Username already taken' : null}
          error={values.usernameTaken}
        />
      </Grid>
      <Grid item xs={12} className={classes.roomField}>
        <TextField
          type="number"
          label="Room code"
          onInput={async (e) => {
            const el = e.target as HTMLInputElement;
            const { value } = el;
            el.value = value.slice(0, 4).replace(/\D/g, '');
            setValues({
              ...values,
              roomCode: el.value,
              invalidCode: !(await roomCodeExists(value)),
              usernameTaken: await isUsernameTaken(el.value, values.username),
            });
          }}
          onKeyPress={handleRoomCodeKeyPress}
          helperText={
            values.invalidCode && isValidCode(values.roomCode)
              ? 'Incorrect code'
              : null
          }
          error={values.invalidCode && isValidCode(values.roomCode)}
        />
      </Grid>
      <Grid item>
        <Button
          className={classes.hostButton}
          onClick={createAndJoinRoom}
          disabled={
            !(values.username !== '' && !values.hosting && !values.joining)
          }
        >
          Host room
        </Button>
        <Button
          className={classes.joinButton}
          onClick={joinRoom}
          disabled={
            !(
              values.username !== '' &&
              !values.usernameTaken &&
              isValidCode(values.roomCode) &&
              !values.invalidCode &&
              !values.hosting &&
              !values.joining
            )
          }
        >
          Join room
        </Button>
        <HostDialog values={values} setValues={setValues} />
        <JoinDialog values={values} setValues={setValues} />
      </Grid>
    </Grid>
  );
};

export default Content;

import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

type PatentlyStupidProblemProps = {
  sendPlayerProblem: (problem: string) => Promise<void>;
  currentProblems: Record<string, string>;
};

const Problem: React.FC<PatentlyStupidProblemProps> = ({
  sendPlayerProblem: sendProblem,
  currentProblems,
}) => {
  const [validated, setValidated] = useState(false);
  const [problem, setProblem] = useState('');

  const onValidateProblem = async () => {
    if (problem.length === 0) return;
    setValidated(true);
    await sendProblem(problem);
  };

  const getValidations = () => {
    const problemsEntries =
      currentProblems! &&
      Object.entries(currentProblems).map(([uid, problems]) => (
        <Grid item key={uid}>
          {problems ? (
            <CheckCircleIcon fontSize="large" htmlColor="green" />
          ) : (
            <CancelIcon fontSize="large" htmlColor="red" />
          )}
        </Grid>
      ));
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Your problem: <b>{problem}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            The other players are stating their problem...
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          spacing={4}
          xs={12}
        >
          {problemsEntries}
        </Grid>
      </>
    );
  };

  const getContent = () => {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            State a ridiculous problem that you wish to be solved:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Ridiculous problem"
            fullWidth
            multiline
            rows={1}
            rowsMax={3}
            disabled={validated}
            onInput={async (e) => {
              const el = e.target as HTMLInputElement;
              const { value } = el;
              setProblem(value);
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={onValidateProblem}
            disabled={problem.length === 0}
          >
            {validated ? 'Validated!' : 'Validate'}
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      {validated ? getValidations() : getContent()}
    </Grid>
  );
};

export default Problem;

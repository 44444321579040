import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import React from 'react';
import { SubmissionResult } from './Vote';

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  green: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
  },
}));

type ResultCardProps = {
  result: SubmissionResult;
  definedWord: string;
};

const ResultCard: React.FC<ResultCardProps> = ({ definedWord, result }) => {
  const classes = useStyles();

  const avatar = <Avatar className={classes.green}>{result.voteCount}</Avatar>;

  return (
    <Card>
      <CardHeader
        avatar={avatar}
        title={
          <Typography variant="h5" component="h2">
            {result.playerName}
          </Typography>
        }
      />
      <CardContent>
        <Typography variant="body2" component="p">
          <b>{definedWord}</b>: {result.definition}
        </Typography>
      </CardContent>
    </Card>
  );
};

type ResultsProps = {
  definedWord: string;
  results: SubmissionResult[];
  isOwner: boolean;
  endGame: () => Promise<void>;
};

const Results: React.FC<ResultsProps> = ({
  definedWord,
  results,
  isOwner,
  endGame,
}) => {
  const resultElements =
    results! &&
    results.map((result) => (
      <Grid item key={result.playerUID}>
        <ResultCard definedWord={definedWord!} result={result} />
      </Grid>
    ));

  return (
    <Grid container justify="center" xs={12} spacing={1}>
      <Grid item container spacing={3} justify="center">
        {resultElements}
      </Grid>
      {isOwner && (
        <Grid item>
          <Button variant="contained" color="primary" onClick={endGame}>
            Next
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Results;

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  green: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
  },
}));

export type Score = {
  founderScore: number;
  inventorScore: number;
};

const ResultCard: React.FC<PatentlyStupidResult> = ({
  name,
  founder,
  inventor,
  score,
}) => {
  const classes = useStyles();

  const avatar = (
    <Avatar className={classes.green}>
      {score.inventorScore + score.founderScore}
    </Avatar>
  );

  return (
    <Card>
      <CardHeader
        avatar={avatar}
        title={
          <Typography variant="h5" component="h2">
            {name}
          </Typography>
        }
      />
      <CardContent>
        <Typography variant="body2" component="p">
          Inventor:{' '}
          <b>
            +{score.inventorScore} ({inventor})
          </b>
        </Typography>
        <Typography variant="body2" component="p">
          Founder:{' '}
          <b>
            +{score.founderScore} ({founder})
          </b>
        </Typography>
      </CardContent>
    </Card>
  );
};

export type PatentlyStupidResult = {
  uid: string;
  name: string;
  founder: string;
  inventor: string;
  score: Score;
};

type PatentlyStupidResultsProps = {
  results: PatentlyStupidResult[];
  isOwner: boolean;
  onHostValidate: () => Promise<void>;
};

const Results: React.FC<PatentlyStupidResultsProps> = ({
  results,
  isOwner,
  onHostValidate,
}) => {
  const resultElements = results.map((result) => (
    <Grid item key={result.uid}>
      <ResultCard
        uid={result.uid}
        name={result.name}
        founder={result.founder}
        inventor={result.inventor}
        score={result.score}
      />
    </Grid>
  ));

  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      <Grid item container spacing={3} justify="center" xs={12}>
        {resultElements}
      </Grid>
      {isOwner && (
        <Grid item>
          <Button variant="contained" color="primary" onClick={onHostValidate}>
            Next
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Results;

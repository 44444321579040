import randomElement from '../components/games/Dictionarium/utility';
import { getFirestore } from '../config/firebase';
import {
  CollectionRoomUsers,
  CollectionRoom,
  GameData,
  PatentlyStupidPhase,
  DictionariumPhase,
  WordSpudPhase,
  games,
} from './types';

const db = getFirestore();
const rooms = db.collection('rooms');

/* Room utilities */

export const isValidCode = (code: string): boolean => code.length === 4;

export const roomCodeExists = async (code: string): Promise<boolean> => {
  if (code.length !== 4) return false;
  const results = await rooms.doc(code).get();
  return results.exists;
};

export const registerRoom = async (
  code: string,
  uid: string,
  username: string
): Promise<void> => {
  const usersRecord: CollectionRoomUsers = {};
  usersRecord[uid] = { name: username, score: 0 };
  const data: CollectionRoom = {
    owner: uid,
    users: usersRecord,
    status: 'waiting',
  };
  await rooms.doc(code).set(data);
};

const playedGames: Record<string, boolean> = Object.fromEntries(
  games.map((game) => [game, false])
);

export const getGameData = (): GameData => {
  const remainingEntries = Object.entries(playedGames)
    .filter(([, played]) => !played)
    .map(([curGame]) => curGame);

  if (remainingEntries.length === 0)
    games.forEach((game) => {
      playedGames[game] = false;
      remainingEntries.push(game);
    });

  const game = randomElement(remainingEntries);
  playedGames[game] = true;
  switch (game) {
    case 'dictionarium':
      return {
        game: 'dictionarium',
        data: {
          currentPhase: DictionariumPhase.rules,
        },
      };
    case 'patentlystupid':
      return {
        game: 'patentlystupid',
        data: {
          currentPhase: PatentlyStupidPhase.rules,
        },
      };
    case 'wordspud':
    default:
      return {
        game: 'wordspud',
        data: {
          currentPhase: WordSpudPhase.rules,
        },
      };
  }
};

export const startRoom = async (code: string): Promise<void> => {
  const data = getGameData();
  await rooms.doc(code).update({ status: 'ingame', gameData: data });
};

export const addUserToRoom = async (
  code: string,
  uid: string,
  username: string
): Promise<void> => {
  if (code.length !== 4) return;
  const room = await rooms.doc(code).get();
  const data = room.data() as CollectionRoom;
  if (data.users[uid] == null) {
    data.users[uid] = { name: username, score: 0 };
    await room.ref.update({ users: data.users });
  }
};

export const removeUserFromRoom = async (
  code: string,
  uid: string
): Promise<void> => {
  if (!isValidCode(code)) return;
  const room = await rooms.doc(code).get();
  const data = room.data() as CollectionRoom;
  delete data.users[uid];
  await room.ref.update({ users: data.users });
};

export const deleteRoom = async (code: string): Promise<void> => {
  if (!isValidCode) return;
  await rooms.doc(code).delete();
};

export const isUsernameTaken = async (
  code: string,
  username: string
): Promise<boolean> => {
  if (!isValidCode(code)) return false;
  const room = await rooms.doc(code).get();
  const data = room.data() as CollectionRoom | undefined;
  return (
    data != null &&
    Object.values(data.users).filter((user) => user.name === username)
      .length !== 0
  );
};

export type ScoreUpdate = { uid: string; increment: number };

export const addUserScores = async (
  code: string,
  updates: ScoreUpdate[]
): Promise<void> => {
  const room = await rooms.doc(code).get();
  const data = room.data() as CollectionRoom;
  updates.forEach(({ uid, increment }) => {
    data.users[uid].score += increment;
  });
  await room.ref.update({ users: data.users });
};

/* Game utilities */

export const setGameData = async (
  code: string,
  gameData: GameData,
  partial = false
): Promise<void> => {
  if (partial) await rooms.doc(code).set({ gameData }, { merge: true });
  else await rooms.doc(code).update({ gameData });
};

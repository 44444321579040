import firebase from 'firebase/app';
import {
  createStyles,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import words from '../../../data/wordspud-prefixes.json';
import {
  CollectionRoomUsers,
  WordSpudData,
  WordSpudPhase,
  WordSpudPromptingData,
  WordSpudResultsData,
  WordSpudVotingData,
} from '../../../collection/types';
import Prompting from './Prompting';
import Rules from './Rules';
import randomElement from '../Dictionarium/utility';
import Voting from './Voting';
import Results, { WordSpudResult } from './Results';
import { ScoreUpdate } from '../../../collection/utility';

type WordSpudProps = {
  user: firebase.User;
  gameData: WordSpudData;
  isOwner: boolean;
  users: CollectionRoomUsers;
  updateData: (gameData: WordSpudData) => Promise<void>;
  updatePartialData: (gameData: WordSpudData) => Promise<void>;
  onGameEnd: () => Promise<void>;
  updateScores: (updates: ScoreUpdate[]) => Promise<void>;
};

const getRandomWord = (): string => {
  return randomElement(words);
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
  })
);

const WordSpud: React.FC<WordSpudProps> = ({
  gameData,
  users,
  user,
  updateData,
  updatePartialData,
  onGameEnd,
  isOwner,
  updateScores,
}) => {
  const classes = useStyles();

  const startGame = async (): Promise<void> => {
    const initScores: Record<string, number> = Object.fromEntries(
      Object.entries(users).map(([uid]) => [uid, 0])
    );
    await updateData({
      currentPhase: WordSpudPhase.prompting,
      currentExpression: getRandomWord(),
      currentTurnPlayer: randomElement(Object.keys(users)),
      scores: initScores,
    });
  };

  const setPlayerChoice = async (playerChoice: string) => {
    const currentData = gameData as WordSpudPromptingData;
    await updateData({
      currentPhase: WordSpudPhase.voting,
      currentExpression: currentData.currentExpression,
      currentTurnPlayer: currentData.currentTurnPlayer,
      playerChoice,
      votes: {},
      scores: currentData.scores,
    });
  };

  const setPlayerVote = async (vote: boolean) => {
    const currentData: WordSpudVotingData = { votes: {} };
    currentData.votes![user.uid] = vote;
    await updatePartialData(currentData);
  };

  const getResults = (): WordSpudResult[] => {
    const currentData = gameData as WordSpudResultsData;
    return Object.entries(currentData.scores)
      .map(([uid, score]) => ({
        uid,
        name: users[uid].name,
        score,
      }))
      .sort((a, b) => b.score - a.score);
  };

  useEffect(() => {
    if (!isOwner) return;
    if (
      gameData.currentPhase === WordSpudPhase.voting &&
      gameData.votes! &&
      Object.values(gameData.votes).length >= Object.values(users).length - 1
    ) {
      const playerScore = Object.values(gameData.votes)
        .map((vote) => (vote ? 1 : -1))
        .reduce((sum, vote) => sum + vote, 0);
      if (playerScore > 0) {
        // eslint-disable-next-line no-unused-vars
        const { [gameData.currentTurnPlayer!]: last, ...otherUsers } = users; // exclude previous player from selection
        updateData({
          currentPhase: WordSpudPhase.prompting,
          currentExpression: gameData.playerChoice!,
          currentTurnPlayer: randomElement(Object.keys(otherUsers)),
          scores: {
            ...gameData.scores,
            [gameData.currentTurnPlayer!]:
              gameData.scores![gameData.currentTurnPlayer!] + 1,
          },
        });
      } else {
        const newScores = {
          ...gameData.scores!,
          // prevent a player's score from dropping below 0
          [gameData.currentTurnPlayer!]: Math.max(
            gameData.scores![gameData.currentTurnPlayer!] - 1,
            0
          ),
        };

        const maxScore = Math.max(
          ...Object.entries(newScores!).map(([, score]) => score)
        );

        const scaledScores = Object.fromEntries(
          Object.entries(newScores!).map(([uid, score]) => [
            uid,
            maxScore > 0 ? Math.ceil((10 * score) / maxScore) : 0,
          ])
        );

        updateData({
          currentPhase: WordSpudPhase.results,
          scores: scaledScores,
        });
        updateScores(
          Object.entries(scaledScores).map(([uid, score]) => ({
            uid,
            increment: score,
          }))
        );
      }
    }
  }, [gameData, isOwner, updateData, updateScores, users]);

  const getPhaseComponent = () => {
    switch (gameData.currentPhase) {
      case WordSpudPhase.rules:
        return <Rules isOwner={isOwner} onHostValidate={startGame} />;
      case WordSpudPhase.prompting:
        return (
          <Prompting
            prefixExpression={gameData.currentExpression}
            isOwnTurn={gameData.currentTurnPlayer === user.uid}
            turnPlayerName={users[gameData.currentTurnPlayer].name}
            sendPlayerChoice={setPlayerChoice}
          />
        );
      case WordSpudPhase.voting:
        return (
          <Voting
            prefixExpression={gameData.currentExpression!}
            isOwnTurn={gameData.currentTurnPlayer! === user.uid}
            turnPlayerName={users[gameData.currentTurnPlayer!].name}
            chosenExpression={gameData.playerChoice!}
            sendVote={setPlayerVote}
            currentVotes={gameData.votes!}
          />
        );
      case WordSpudPhase.results:
        return (
          <Results
            results={getResults()}
            isOwner={isOwner}
            onHostValidate={onGameEnd}
          />
        );
      default:
        return null;
    }
  };

  const stepper = isDesktop && (
    <Stepper activeStep={gameData.currentPhase}>
      <Step key={WordSpudPhase.rules}>
        <StepLabel>Rules</StepLabel>
      </Step>
      <Step key={WordSpudPhase.prompting}>
        <StepLabel>Prompt!</StepLabel>
      </Step>
      <Step key={WordSpudPhase.voting}>
        <StepLabel>Vote!</StepLabel>
      </Step>
      <Step key={WordSpudPhase.results}>
        <StepLabel>Results</StepLabel>
      </Step>
    </Stepper>
  );

  return (
    <Paper className={classes.paper}>
      <Typography variant="h2" align="center">
        Word Spud
      </Typography>
      {stepper}

      {getPhaseComponent()}
    </Paper>
  );
};

export default WordSpud;

import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';

type PatentlyStupidRulesProps = {
  isOwner: boolean;
  onHostValidate: () => Promise<void>;
};

const Rules: React.FC<PatentlyStupidRulesProps> = ({
  isOwner,
  onHostValidate,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          Everybody is going to write a ridiculous problem that they want to be
          solved by the other players&apos; invention.
          <br />
          You&apos;ll then present your invention, and finally everyone will
          invest in the best inventions!
        </Typography>
      </Grid>
      {isOwner && (
        <Grid container justify="center" xs={12}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onHostValidate}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Rules;

import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

type PatentlyStupidInventProps = {
  sendPlayerInvention: (invention: Invention) => Promise<void>;
  problemFounderUid: string;
  currentProblem: string;
  currentInventions: Record<string, Invention>;
};

export type Invention = {
  problemFounderUid: string;
  name: string;
  tagline: string;
};

const Invent: React.FC<PatentlyStupidInventProps> = ({
  sendPlayerInvention: sendInvention,
  problemFounderUid,
  currentProblem,
  currentInventions,
}) => {
  const [validated, setValidated] = useState(false);
  const [invention, setInvention] = useState({
    problemFounderUid,
    name: '',
    tagline: '',
  });

  const onValidateInvention = async () => {
    if (invention.name.length === 0 || invention.tagline.length === 0) return;
    setValidated(true);
    await sendInvention(invention);
  };

  const getValidations = () => {
    const inventionsEntries =
      currentInventions! &&
      Object.entries(currentInventions).map(([uid, inventions]) => (
        <Grid item key={uid}>
          {inventions ? (
            <CheckCircleIcon fontSize="large" htmlColor="green" />
          ) : (
            <CancelIcon fontSize="large" htmlColor="red" />
          )}
        </Grid>
      ));
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Your invention: <b>{invention.name}</b>
            <br />
            Your tagline: <b>{invention.tagline}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Prepare your speech while the other players are still inventing...
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          spacing={4}
          xs={12}
        >
          {inventionsEntries}
        </Grid>
      </>
    );
  };

  const getContent = () => {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            The problem is: <b>{currentProblem!}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Name and tagline your wonderful invention that address this problem:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Name of your invention"
            fullWidth
            disabled={validated}
            onInput={async (e) => {
              const el = e.target as HTMLInputElement;
              const { value } = el;
              setInvention({ ...invention, name: value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Tagline of your invention"
            fullWidth
            disabled={validated}
            multiline
            rows={1}
            rowsMax={4}
            onInput={async (e) => {
              const el = e.target as HTMLInputElement;
              const { value } = el;
              setInvention({ ...invention, tagline: value });
            }}
          />
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={onValidateInvention}
            disabled={
              invention.name.length === 0 || invention.tagline.length === 0
            }
          >
            {validated ? 'Validated!' : 'Validate'}
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={3} alignItems="center" justify="center">
      {validated ? getValidations() : getContent()}
    </Grid>
  );
};

export default Invent;

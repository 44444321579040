import React, { useState } from 'react';
import {
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export type PlayerSubmission = {
  playerName: string;
  playerUID: string;
  definition: string;
};

export type SubmissionResult = {
  playerName: string;
  playerUID: string;
  definition: string;
  voteCount: number;
};

type VoteCardProps = {
  submission: PlayerSubmission;
  definedWord: string;
  selected: boolean;
  onSelect: () => Promise<void>;
};

const VoteCard: React.FC<VoteCardProps> = ({
  definedWord,
  submission,
  selected,
  onSelect,
}) => {
  return (
    <ButtonBase onClick={onSelect}>
      <Card>
        <CardHeader
          avatar={selected ? <CheckCircleIcon /> : null}
          title={
            <Typography variant="h5" component="h2">
              {definedWord!}
            </Typography>
          }
        />
        <CardContent>
          <Typography variant="body2" component="p">
            {submission.definition}
          </Typography>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

type VoteProps = {
  otherSubmissions: PlayerSubmission[];
  definedWord: string;
  sendVote: (target: string) => Promise<void>;
};

const Vote: React.FC<VoteProps> = ({
  definedWord,
  otherSubmissions,
  sendVote,
}) => {
  const [selected, setSelected] = useState<string | null>();

  const onCardSelected = async (selectedUID: string): Promise<void> => {
    setSelected(selectedUID);
    await sendVote(selectedUID);
  };

  const cards =
    otherSubmissions! &&
    otherSubmissions
      .sort((a, b) => {
        if (a.playerName < b.playerName) return -1;
        if (a.playerName > b.playerName) return 1;
        return 0;
      })
      .map((submission) => (
        <Grid item key={submission.playerUID}>
          <VoteCard
            definedWord={definedWord!}
            submission={submission}
            selected={submission.playerUID === selected}
            onSelect={() => onCardSelected(submission.playerUID)}
          />
        </Grid>
      ));

  return (
    <Grid container spacing={3}>
      {cards}
    </Grid>
  );
};

export default Vote;

import firebase from 'firebase/app';
import {
  createStyles,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import {
  CollectionRoomUsers,
  PatentlyStupidData,
  PatentlyStupidInventData,
  PatentlyStupidInvestData,
  PatentlyStupidPhase,
  PatentlyStupidPresentData,
  PatentlyStupidProblemData,
  PatentlyStupidResultsData,
} from '../../../collection/types';
import Rules from './Rules';
import Problem from './Problem';
import Invent, { Invention } from './Invent';
import Present from './Present';
import Invest, { Investment } from './Invest';
import Results, { PatentlyStupidResult, Score } from './Results';
import { ScoreUpdate } from '../../../collection/utility';

type PatentlyStupidProps = {
  user: firebase.User;
  gameData: PatentlyStupidData;
  isOwner: boolean;
  users: CollectionRoomUsers;
  updateData: (gameData: PatentlyStupidData) => Promise<void>;
  updatePartialData: (gameData: PatentlyStupidData) => Promise<void>;
  updateScores: (updates: ScoreUpdate[]) => Promise<void>;
  onGameEnd: () => Promise<void>;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
  })
);

const PatentlyStupid: React.FC<PatentlyStupidProps> = ({
  gameData,
  user,
  users,
  updateData,
  updatePartialData,
  updateScores,
  onGameEnd,
  isOwner,
}) => {
  const classes = useStyles();

  const startGame = async (): Promise<void> => {
    await updateData({
      currentPhase: PatentlyStupidPhase.problem,
    });
  };

  const setPlayerProblem = async (playerProblem: string) => {
    const currentData: PatentlyStupidProblemData = { problems: {} };
    currentData.problems![user.uid] = playerProblem;
    await updatePartialData(currentData);
  };

  const setPlayerInvention = async (playerInvention: Invention) => {
    const currentData: PatentlyStupidInventData = { inventions: {} };
    currentData.inventions![user.uid] = playerInvention;
    await updatePartialData(currentData);
  };

  const nextPlayer = async (): Promise<void> => {
    const currentData = gameData as PatentlyStupidPresentData;
    if (currentData.turnsPlayer.length <= 1) {
      await updatePartialData({
        currentPhase: PatentlyStupidPhase.invest,
      });
    } else {
      currentData.turnsPlayer.splice(0, 1);
      await updateData(currentData);
    }
  };

  const setPlayerInvestment = async (playerInvestment: Investment) => {
    const currentData: PatentlyStupidInvestData = { investments: {} };
    currentData.investments![user.uid] = playerInvestment;
    await updatePartialData(currentData);
  };

  const getResults = (): PatentlyStupidResult[] => {
    const currentData = gameData as PatentlyStupidResultsData;
    return Object.entries(currentData.scores)
      .map(([uid, score]) => ({
        uid,
        name: users[uid].name,
        founder: currentData.inventions![
          Object.keys(currentData.inventions!).find(
            (key) => currentData.inventions![key].problemFounderUid === uid
          )!
        ].name,
        inventor: currentData.inventions![uid].name,
        score,
      }))
      .sort(
        (a, b) =>
          b.score.founderScore +
          b.score.inventorScore -
          (a.score.founderScore + a.score.inventorScore)
      );
  };

  useEffect(() => {
    if (!isOwner) return;
    if (
      gameData.currentPhase === PatentlyStupidPhase.problem &&
      gameData.problems! &&
      Object.values(gameData.problems).length >= Object.values(users).length
    ) {
      updatePartialData({
        currentPhase: PatentlyStupidPhase.invent,
        solvers: (() => {
          const solvers: Record<string, string> = {};
          const usersUid = Object.keys(users);
          const cpyUsersUid = [...usersUid];
          // Sattolo cycle (derangement algorithm)
          for (let i = usersUid.length - 1; i > 0; i -= 1) {
            const j = Math.floor(Math.random() * i);
            [usersUid[i], usersUid[j]] = [usersUid[j], usersUid[i]];
          }
          usersUid.forEach((value, index) => {
            solvers[value] = cpyUsersUid[index];
          });
          return solvers;
        })(),
      });
    }
    if (
      gameData.currentPhase === PatentlyStupidPhase.invent &&
      gameData.inventions! &&
      Object.values(gameData.inventions).length >= Object.values(users).length
    ) {
      updatePartialData({
        currentPhase: PatentlyStupidPhase.present,
        turnsPlayer: (() => {
          const usersUid = Object.keys(gameData.inventions);
          // Fisher–Yates shuffle
          for (let i = usersUid.length - 1; i > 0; i -= 1) {
            const j = Math.floor(Math.random() * (i + 1));
            [usersUid[i], usersUid[j]] = [usersUid[j], usersUid[i]];
          }
          return usersUid;
        })(),
      });
    }
    if (
      gameData.currentPhase === PatentlyStupidPhase.invest &&
      gameData.investments! &&
      Object.values(gameData.investments).length >= Object.values(users).length
    ) {
      const tmpScores: { [p: string]: number } = {};
      Object.keys(users).forEach((uid) => {
        tmpScores[uid] = 0;
      });
      Object.values(gameData.investments).forEach((investment) => {
        tmpScores[investment.bigStack] += 3;
        tmpScores[investment.mediumStack] += 2;
        tmpScores[investment.smallStack] += 1;
      });
      if ('' in tmpScores) delete tmpScores[''];
      const scores: {
        [uid: string]: Score;
      } = {};
      Object.keys(users).forEach((uid) => {
        scores[uid] = { founderScore: 0, inventorScore: 0 };
      });
      Object.entries(tmpScores).forEach(([uid, score]) => {
        // Max score: 7 + 3 = 10
        // We reward the inventors
        scores[uid].inventorScore += Math.ceil(
          (2.25 * score) / Object.keys(gameData.investments!).length
        );
        // We reward the problem founders
        scores[
          gameData.inventions![uid].problemFounderUid
        ].founderScore += Math.ceil(
          (1.5 * score) / Object.keys(gameData.investments!).length
        );
      });
      updatePartialData({
        currentPhase: PatentlyStupidPhase.results,
        scores,
      });
      updateScores(
        Object.entries(scores).map(([uid, score]) => ({
          uid,
          increment: Math.max(score.founderScore + score.inventorScore, 0),
        }))
      );
    }
  }, [gameData, isOwner, updateData, updatePartialData, updateScores, users]);

  const getPhaseComponent = () => {
    switch (gameData.currentPhase) {
      case PatentlyStupidPhase.rules:
        return <Rules isOwner={isOwner} onHostValidate={startGame} />;
      case PatentlyStupidPhase.problem:
        return (
          <Problem
            sendPlayerProblem={setPlayerProblem}
            currentProblems={gameData.problems!}
          />
        );
      case PatentlyStupidPhase.invent:
        return (
          <Invent
            sendPlayerInvention={setPlayerInvention}
            problemFounderUid={gameData.solvers![user.uid]}
            currentProblem={gameData.problems![gameData.solvers![user.uid]]}
            currentInventions={gameData.inventions!}
          />
        );
      case PatentlyStupidPhase.present:
        return (
          <Present
            sendNextPlayer={nextPlayer}
            turnPlayerName={users[gameData.turnsPlayer[0]].name}
            currentProblem={
              gameData.problems![
                gameData.inventions![gameData.turnsPlayer[0]].problemFounderUid
              ]
            }
            currentInvention={gameData.inventions![gameData.turnsPlayer[0]]}
            isOwnTurn={gameData.turnsPlayer[0] === user.uid}
            isOwner={isOwner}
          />
        );
      case PatentlyStupidPhase.invest:
        return (
          <Invest
            sendPlayerInvestment={setPlayerInvestment}
            currentProblems={gameData.problems!}
            currentInventions={gameData.inventions!}
            currentInvestments={gameData.investments!}
            userUid={user.uid}
          />
        );
      case PatentlyStupidPhase.results:
        return (
          <Results
            results={getResults()}
            isOwner={isOwner}
            onHostValidate={onGameEnd}
          />
        );
      default:
        return null;
    }
  };

  const stepper = isDesktop && (
    <Stepper activeStep={gameData.currentPhase}>
      <Step key={PatentlyStupidPhase.rules}>
        <StepLabel>Rules</StepLabel>
      </Step>
      <Step key={PatentlyStupidPhase.problem}>
        <StepLabel>Problem!</StepLabel>
      </Step>
      <Step key={PatentlyStupidPhase.invent}>
        <StepLabel>Invent!</StepLabel>
      </Step>
      <Step key={PatentlyStupidPhase.present}>
        <StepLabel>Present!</StepLabel>
      </Step>
      <Step key={PatentlyStupidPhase.invest}>
        <StepLabel>Invest!</StepLabel>
      </Step>
      <Step key={PatentlyStupidPhase.results}>
        <StepLabel>Results</StepLabel>
      </Step>
    </Stepper>
  );

  return (
    <Paper className={classes.paper}>
      <Typography variant="h2" align="center">
        Patently Stupid
      </Typography>
      {stepper}

      {getPhaseComponent()}
    </Paper>
  );
};

export default PatentlyStupid;

import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { getFirestore } from '../../config/firebase';
import {
  CollectionRoom,
  DictionariumData,
  PatentlyStupidData,
  WordSpudData,
} from '../../collection/types';
import Dictionarium from '../games/Dictionarium/Dictionarium';
import UserContext from '../../context/user-context';
import {
  addUserScores,
  getGameData,
  ScoreUpdate,
  setGameData,
} from '../../collection/utility';
import WordSpud from '../games/WordSpud/WordSpud';
import PatentlyStupid from '../games/PatentlyStupid/PatentlyStupid';

const getRoomCode = (pathname: string): string | null => {
  const re = /\/room\/(\d{4})/;
  const result = pathname.match(re);
  if (result == null || result.length === 0) return null;
  return result[1];
};

const db = getFirestore();
const roomsCollection = db.collection('rooms');

const Content: React.FC = () => {
  const { pathname } = useLocation();
  const code = getRoomCode(pathname) || '<invalid>';
  const user = useContext(UserContext)!;

  const roomsQuery = roomsCollection.doc(code);
  const [room] = useDocumentData<CollectionRoom>(roomsQuery);
  if (room == null) {
    return (
      <Typography variant="h2" style={{ margin: 'auto' }}>
        Invalid code: {code}
      </Typography>
    );
  }

  const startNewGame = async (): Promise<void> => {
    const newData = getGameData();
    setGameData(code, newData);
  };

  const updateScores = async (updates: ScoreUpdate[]): Promise<void> => {
    await addUserScores(code, updates);
  };

  switch (room.status) {
    case 'ingame':
      if (room.gameData?.game === 'dictionarium')
        return (
          <Dictionarium
            gameData={room.gameData.data}
            user={user}
            users={room.users}
            isOwner={user.uid === room.owner}
            updateData={async (gameData: DictionariumData) =>
              setGameData(code, { game: 'dictionarium', data: gameData })
            }
            updatePartialData={async (gameData: DictionariumData) =>
              setGameData(code, { game: 'dictionarium', data: gameData }, true)
            }
            onGameEnd={startNewGame}
            updateScores={updateScores}
          />
        );
      if (room.gameData?.game === 'wordspud')
        return (
          <WordSpud
            gameData={room.gameData.data}
            user={user}
            users={room.users}
            isOwner={user.uid === room.owner}
            updateData={async (gameData: WordSpudData) =>
              setGameData(code, { game: 'wordspud', data: gameData })
            }
            updatePartialData={async (gameData: WordSpudData) =>
              setGameData(code, { game: 'wordspud', data: gameData }, true)
            }
            onGameEnd={startNewGame}
            updateScores={updateScores}
          />
        );
      if (room.gameData?.game === 'patentlystupid')
        return (
          <PatentlyStupid
            gameData={room.gameData.data}
            user={user}
            users={room.users}
            isOwner={user.uid === room.owner}
            updateData={async (gameData: PatentlyStupidData) =>
              setGameData(code, { game: 'patentlystupid', data: gameData })
            }
            updatePartialData={async (gameData: PatentlyStupidData) =>
              setGameData(
                code,
                { game: 'patentlystupid', data: gameData },
                true
              )
            }
            onGameEnd={startNewGame}
            updateScores={updateScores}
          />
        );
      break;
    default:
  }

  return (
    <Typography variant="h2" style={{ margin: 'auto' }}>
      Welcome! You are in room: {code}
    </Typography>
  );
};

export default Content;

import { Grid, IconButton, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useState } from 'react';

type WordSpudVotingProps = {
  prefixExpression: string;
  chosenExpression: string;
  isOwnTurn: boolean;
  turnPlayerName: string;
  currentVotes: Record<string, boolean>;
  sendVote: (vote: boolean) => Promise<void>;
};

const Voting: React.FC<WordSpudVotingProps> = ({
  prefixExpression,
  chosenExpression,
  isOwnTurn,
  turnPlayerName,
  currentVotes,
  sendVote,
}) => {
  const [approved, setApproved] = useState<boolean | undefined>();

  const setVote = async (vote: boolean): Promise<void> => {
    setApproved(vote);
    await sendVote(vote);
  };

  const getContent = () => {
    if (isOwnTurn) {
      const voteEntries =
        currentVotes! &&
        Object.entries(currentVotes).map(([uid, vote]) => (
          <Grid item key={uid}>
            {vote ? (
              <CheckCircleIcon fontSize="large" htmlColor="green" />
            ) : (
              <CancelIcon fontSize="large" htmlColor="red" />
            )}
          </Grid>
        ));
      return (
        <>
          <Grid item xs={12}>
            <Typography variant="h4">
              Your expression: {prefixExpression!} <b>{chosenExpression!}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Other players are voting...</Typography>
          </Grid>
          <Grid item container justify="center" spacing={4} xs={12}>
            {voteEntries}
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h4">
            {turnPlayerName!} has chosen: {prefixExpression!}{' '}
            <b>{chosenExpression!}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Vote below:</Typography>
        </Grid>
        <Grid container justify="center" spacing={4} xs={12}>
          <Grid item>
            <IconButton onClick={() => setVote(true)}>
              <CheckCircleIcon
                fontSize="large"
                htmlColor={approved == null || approved ? 'green' : undefined}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={() => setVote(false)}>
              <CancelIcon
                fontSize="large"
                htmlColor={approved == null || !approved ? 'red' : undefined}
              />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={3}>
      {getContent()}
    </Grid>
  );
};

export default Voting;

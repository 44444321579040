import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';

type WordSpudPromptingProps = {
  prefixExpression: string;
  isOwnTurn: boolean;
  turnPlayerName: string;
  sendPlayerChoice: (expression: string) => Promise<void>;
};

const Prompting: React.FC<WordSpudPromptingProps> = ({
  prefixExpression,
  isOwnTurn,
  turnPlayerName,
  sendPlayerChoice: sendExpression,
}) => {
  const [validated, setValidated] = useState(false);
  const [expression, setExpression] = useState('');

  const onValidateExpression = async () => {
    if (expression.length === 0) return;
    setValidated(true);
    await sendExpression(expression);
  };

  const getContent = () => {
    if (isOwnTurn) {
      return (
        <>
          <Grid item xs={12}>
            <Typography variant="h4">
              Complete the sentence: {prefixExpression} ____
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Word or expression"
              fullWidth
              onInput={async (e) => {
                const el = e.target as HTMLInputElement;
                const { value } = el;
                setExpression(value);
                setValidated(false);
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onValidateExpression}
            >
              {validated ? 'Validated!' : 'Validate'}
            </Button>
          </Grid>
        </>
      );
    }
    return (
      <Grid item xs={12}>
        <Typography variant="h4">
          {turnPlayerName} is completing the sentence: {prefixExpression} ____
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      {getContent()}
    </Grid>
  );
};

export default Prompting;

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const useEmulator = process.env.REACT_APP_USE_EMULATOR != null;

let instance: firebase.app.App | null;
let db: firebase.firestore.Firestore | null;
let auth: firebase.auth.Auth | null;

const createFirebase = (): firebase.app.App => {
  return firebase.initializeApp({
    apiKey: 'AIzaSyBelPtaoEeVCy-cUe3dlFN-e5Xx_ls10ug',
    authDomain: 'english-project-28067.firebaseapp.com',
    projectId: 'english-project-28067',
    storageBucket: 'english-project-28067.appspot.com',
    messagingSenderId: '318910493083',
    appId: '1:318910493083:web:cf40711e7400588f35f13e',
  });
};

export const getFirebase = (): firebase.app.App => {
  if (instance == null) instance = createFirebase();
  return instance;
};

export const getFirestore = (): firebase.firestore.Firestore => {
  if (db == null) {
    db = getFirebase().firestore();
    if (useEmulator && window.location.hostname === 'localhost') {
      db.useEmulator('localhost', 8080);
    }
  }
  return db;
};

export const getAuth = (): firebase.auth.Auth => {
  if (auth == null) {
    auth = getFirebase().auth();
    if (useEmulator && window.location.hostname === 'localhost') {
      auth.useEmulator('http://localhost:9099');
    }
  }
  return auth;
};

import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Grid,
  List,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  green: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
  },
}));

type ResultCardProps = {
  name: string;
  score: number;
};

const ResultCard: React.FC<ResultCardProps> = ({ name, score }) => {
  const classes = useStyles();
  const avatar = (
    <Avatar className={score > 0 ? classes.green : classes.red}>
      {score > 0 ? '+' : ''}
      {score}
    </Avatar>
  );

  return (
    <Card>
      <CardHeader
        avatar={avatar}
        title={
          <Typography variant="h5" component="h2">
            {name}
          </Typography>
        }
      />
    </Card>
  );
};

export type WordSpudResult = {
  uid: string;
  name: string;
  score: number;
};

type WordSpudResultsProps = {
  results: WordSpudResult[];
  isOwner: boolean;
  onHostValidate: () => Promise<void>;
};

const Results: React.FC<WordSpudResultsProps> = ({
  results,
  isOwner,
  onHostValidate,
}) => {
  const resultElements = results.map((result) => (
    <ListItem key={result.uid}>
      <ResultCard name={result.name} score={result.score} />
    </ListItem>
  ));

  return (
    <Grid container spacing={3}>
      <Grid item>
        <List>{resultElements}</List>
      </Grid>
      {isOwner && (
        <Grid container justify="center" xs={12}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onHostValidate}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Results;

import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';

type DictionariumRulesProps = {
  isOwner: boolean;
  onHostValidate: () => Promise<void>;
};

const Rules: React.FC<DictionariumRulesProps> = ({
  isOwner,
  onHostValidate,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          Everyone will be shown the same made-up word. You must invent a
          dictionary entry for that word, and everyone will vote for the best
          definition !
        </Typography>
      </Grid>
      {isOwner && (
        <Grid container justify="center" xs={12}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onHostValidate}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Rules;

import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import StarIcon from '@material-ui/icons/Star';
import React from 'react';

type RoomUsersProps = {
  users: { name: string; uid: string; owner: boolean }[];
};

const RoomUsers: React.FC<RoomUsersProps> = ({ users }) => {
  const components = users.map((user) => (
    <ListItem key={user.uid}>
      <ListItemIcon>{user.owner ? <StarIcon /> : <PersonIcon />}</ListItemIcon>
      <ListItemText primary={user.name} />
    </ListItem>
  ));

  return <List>{components}</List>;
};

export default RoomUsers;

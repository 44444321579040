import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Invention } from './Invent';

type PatentlyStupidPresentProps = {
  sendNextPlayer: () => Promise<void>;
  turnPlayerName: string;
  currentProblem: string;
  currentInvention: Invention;
  isOwnTurn: boolean;
  isOwner: boolean;
};

const Present: React.FC<PatentlyStupidPresentProps> = ({
  sendNextPlayer: nextPlayer,
  turnPlayerName,
  currentProblem,
  currentInvention,
  isOwnTurn,
  isOwner,
}) => {
  const getContent = () => {
    if (isOwnTurn) {
      return (
        <>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              It&apos;s your turn!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              The problem was: <b>{currentProblem!}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              Present your invention: <b>{currentInvention!.name}</b>
              <br />
              Its tagline is: <b>{currentInvention!.tagline}</b>
            </Typography>
          </Grid>
        </>
      );
    }
    return (
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          <b>{turnPlayerName!}</b> is going to explain how to solve this
          problem: <br />
          <b>{currentProblem!}</b>
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3} alignItems="center" justify="center" xs={12}>
      {getContent()}
      {isOwner && (
        <Grid item>
          <Button variant="contained" color="primary" onClick={nextPlayer}>
            Next
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Present;
